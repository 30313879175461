/**eslint-disable */
import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Swal from "sweetalert2";

const routes: Array<RouteRecordRaw> = [ 
  {
    path: "/",
    name: "home",
    component: () => import(/*webpackChunkName: "home" */'../views/Home.vue'),
  },
  {
    path: "/Menu",
    name: "Menu",
    component: () => import(/*webpackChunkName: "Menu" */'../views/Menu.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: "/MenuGestor",
    name: "MenuGestor",
    component: () => import(/*webpackChunkName: "MenuGestor" */'../views/Gestor/MenuGestor.vue'),
    meta: { requiresAuth: true },
  },  
  {
    path: "/MenuGestor/:id",
    name: "TablaGestor",
    component: () => import(/*webpackChunkName: "TablaGestor" */'../views/Gestor/TablaGestor.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: "/MenuInstructor/:id",
    name: "MenuInstructor",
    component: () => import(/*webpackChunkName: "MenuInstructor" */'../views/Instructor/MenuInstructor.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: "/Manuales",
    name: "Manuales",
    component: () => import(/*webpackChunkName: "MenuInstructor" */'../views/Manuales/Manuales.vue'),
  },

  // {
  //   path: "/ManualAdmin",
  //   name: "ManualAdmin",
  //   component: () => import(/*webpackChunkName: "MenuInstructor" */'../components/reports/Admin.vue'),
  // },

  {
    path: "/:catchAll(.*)",
    name: "NoFound",
    component: () => import(/*webpackChunkName: "NoFound" */'../components/NoFound.vue'),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,

});
// /**eslint-disable */

router.beforeEach((to, from, next) => {
  let usuario: any = null;

  usuario = JSON.parse(sessionStorage.getItem('usuario') as string)

  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (usuario) {
      next();
    } else {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Primero debes iniciar sesión",
        text: "Para acceder a esta sección de la pagina debes iniciar sesión",
        showConfirmButton: false,
        timer: 1500,
      });
      router.push ({name: "home"})
    }
  } else {
    next();
  }
});


export default router;