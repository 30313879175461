import { createApp } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import App from "./App.vue";
import "./registerServiceWorker";
import VueLazyLoad from 'vue3-lazyload'
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.css"
import '@/css/material-dashboard.css?v=3.0.0'



library.add(fas);

createApp(App).use(store).use(router).use(VueLazyLoad).component('fa',FontAwesomeIcon).mount("#app");